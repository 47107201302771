// @flow
import React from 'react';
import { graphql } from 'gatsby';
import type { FrontMatter, ImageType } from '../../utils/types';
import {
  AllImagesLink,
  CanyonRatingCard,
  Carousel,
  GlossaryLink as G,
  Image,
  Layout,
  Link,
  withFrontMatter,
} from '../../components';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _01: ImageType,
    _02: ImageType,
    _03: ImageType,
    _06: ImageType,
    _08: ImageType,
    _11: ImageType,
    _12: ImageType,
    _13: ImageType,
    _14: ImageType,
    _15: ImageType,
    _16: ImageType,
    _17: ImageType,
  },
};

class Blog_2020_07_24_Crumbling_Canyon extends React.PureComponent<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3AI" title="Crumbling Canyon" />
        <p>
          I misjudged this canyon. I figured it was a crappy crack in a rock
          that someone called a <G>slot canyon</G>. It would be a
          do-it-once-and-never-again just like so many of the other Wasatch
          slots. Turns out it's way better than that. It's a solid "meh" canyon
          good for an after work trip. Also the name "Crumbling" is apt. Every
          step crumbles as it's weighted and many hand holds do the same.
        </p>
        <h3>
          Comparison to{' '}
          <Link to="http://ropewiki.com/Boundary_Canyon">Boundary Canyon</Link>
        </h3>
        <ul>
          <li>
            <G>Approach</G> - Crumbling is better
          </li>
          <li>
            <G>Technical section</G> length - Crumbling is better
          </li>
          <li>
            <G>Exit</G> - Crumbling is better
          </li>
          <li>
            <G>Down climbs</G> - Crumbling is better
          </li>
          <li>Kid friendly - Crumbling is better</li>
          <li>
            Length and ease of <G>bushwhacking</G> - Crumbling is better
          </li>
          <li>
            Beauty - Boundary is <em>contemplate the meaning of life</em>{' '}
            beautiful
          </li>
          <li>Fun factor - Boundary is a much better day</li>
          <li>Overall - Boundary is so much better there's no comparison</li>
        </ul>
        <p>Anyway, let's take a look at some pictures</p>
        <Carousel
          images={[data._01, data._02, data._03]}
          captions={[
            'Heroes of Crumbling Canyon - Jared',
            <>
              Heroes of Crumbling Canyon -{' '}
              <Link to="https://emilyjeppson.com">Emily</Link>
            </>,
            'Heroes of Crumbling Canyon - a snail who was slow on the approach but really took in the scenery',
          ]}
        />
        <Carousel
          images={[data._06, data._08]}
          captions={[
            'Lots of scenic views on the approach',
            "We're like 700 ft up at this point and someone with a chainsaw beat us to the top",
          ]}
        />
        <Image image={data._11} caption="Right near the high point" />
        <Image
          image={data._12}
          caption={
            <>
              First view of the slot near the first <G>rappel</G>
            </>
          }
        />
        <Image image={data._13} caption="Top of the first rappel" />
        <p>
          I heard that all the rappels can be{' '}
          <G id="down-climb">down climbed</G>. I didn't attempt the first rappel
          but I think it could be done. I did down climb all the others.
        </p>
        <Carousel
          images={[data._14, data._15, data._16]}
          captions={[
            'Top of the second rappel',
            'Beautiful slot section between the second and third rappels',
            'Beautiful slot section between the second and third rappels',
          ]}
        />
        <Image
          image={data._17}
          caption={
            <>
              Down climbing the final rappel turns it into an <G>R section</G>
            </>
          }
        />
        <p>
          We spent a lot of time wandering around, practicing rigging, and
          taking pictures. Without this the canyon wouldn't have been nearly as
          fun.
        </p>
        <p>Is it worth doing again? Definitely.</p>
        <AllImagesLink id="2020-07-24-crumbling-canyon" />
      </Layout>
    );
  }
}

export default withFrontMatter('2020-07-24-crumbling-canyon')(
  Blog_2020_07_24_Crumbling_Canyon
);

export const query = graphql`
  query {
    _01: file(
      relativePath: { eq: "images/blog/2020-07-24-crumbling-canyon/_01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _02: file(
      relativePath: { eq: "images/blog/2020-07-24-crumbling-canyon/_02.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _03: file(
      relativePath: { eq: "images/blog/2020-07-24-crumbling-canyon/_03.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _06: file(
      relativePath: { eq: "images/blog/2020-07-24-crumbling-canyon/_06.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _08: file(
      relativePath: { eq: "images/blog/2020-07-24-crumbling-canyon/_08.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _11: file(
      relativePath: { eq: "images/blog/2020-07-24-crumbling-canyon/_11.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _12: file(
      relativePath: { eq: "images/blog/2020-07-24-crumbling-canyon/_12.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _13: file(
      relativePath: { eq: "images/blog/2020-07-24-crumbling-canyon/_13.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _14: file(
      relativePath: { eq: "images/blog/2020-07-24-crumbling-canyon/_14.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _15: file(
      relativePath: { eq: "images/blog/2020-07-24-crumbling-canyon/_15.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _16: file(
      relativePath: { eq: "images/blog/2020-07-24-crumbling-canyon/_16.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _17: file(
      relativePath: { eq: "images/blog/2020-07-24-crumbling-canyon/_17.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
